import { ChartData } from '../../../common/ui/chart';
import { CompanyMood } from '../types';

export const convertCompanyMoodsToNovoChartData = (
  data: CompanyMood[] | undefined,
): ChartData[] => {
  if (data !== undefined) {
    return [...data].reverse().map((it) => {
      return {
        xValue: it.period.format(),
        yValue: it.averageMood,
      };
    });
  } else {
    return [];
  }
};
