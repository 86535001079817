import React from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Tooltip } from '@mui/material';

import { SWITCH_TO_QUARTERLY_MONTH } from '../../../common/config.ts';
import { Period } from '../../../common/types';
import { SpaceBetweenRowContainer } from '../../../common/ui/containers';
import { useEvaluationFormSummary, usePersonalRanking } from '../hooks';
import { RankingContainer, RankingTitle, RankingTypography } from './style';

export const PersonalRank = () => {
  const { t } = useTranslation();
  const period = Period.currentPeriod().previous(1, SWITCH_TO_QUARTERLY_MONTH);
  const { personalRanking, isLoading: isLoadingPersonalRanking } =
    usePersonalRanking(period);
  const { isLoading: isLoadingEvaluationFormSummary } =
    useEvaluationFormSummary(); // if error -> no evaluationForm exists

  return (
    (<Tooltip title={t('page.home.ranking.personal_rank_tooltip')}>
      <RankingContainer>
        <RankingTitle variant={'caption'} color={'gray'}>
          {t('page.home.ranking.personal_rank')}
        </RankingTitle>
        {isLoadingPersonalRanking || isLoadingEvaluationFormSummary ? (
          <CircularProgress size={10} />
        ) : (
          <SpaceBetweenRowContainer>
            {personalRanking ? (
              <RankingTypography sx={{ margin: 0 }} variant={'h1'}>
                {`${personalRanking?.rank} / ${personalRanking?.total}`}
              </RankingTypography>
            ) : (
              /*personalRanking not yet available, 75% must be reviewed*/
              (<RankingTypography sx={{ margin: 0 }} variant={'h1'}>
                {t('page.home.ranking.ranking_not_available')}
              </RankingTypography>)
            )}

            <RankingTypography variant={'caption'} color={'gray'}>
              {period.format()}
            </RankingTypography>
          </SpaceBetweenRowContainer>
        )}
      </RankingContainer>
    </Tooltip>)
  );
};
