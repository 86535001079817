import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { EvaluationForm } from '../../../common/types';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';

export const useEvaluationForm = (onSuccess: () => void) => {
  const { evaluationFormResultService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation<EvaluationForm, AppError, number>(
    ['evaluation-form'],
    async (evaluationFormResultId) => {
      return evaluationFormResultService.fetchEvaluationForm(
        evaluationFormResultId,
      );
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
      onSuccess: () => {
        onSuccess?.();
      },
    },
  );
};
